import { Container } from "@chakra-ui/react";
import "./header.scss";

function HeaderComponent() {

    return (
        <nav className="navbar">
            <Container maxW="container.xl">
                <a href="https://job.jobnow.work/jobs">
                    <img className="jobnow-logo" src="/images/jobnow-logo.webp" alt="หางาน jobnow" />
                </a>
            </Container>
        </nav>
    )
}

export default HeaderComponent;