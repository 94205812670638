import { Spinner } from "@chakra-ui/react";
import { lazy, Suspense, useEffect, useState } from "react";
import {
  Route,
  BrowserRouter as Router,
  Routes,
  Navigate,
} from "react-router-dom";
import HeaderComponent from "./components/header/header";
import "./style.scss";

const JobNowComponent = lazy(() => import('./components/job-now/job-now'));
const IfsComponent = lazy(() => import('./components/ifs/ifs'));
const PcsComponent = lazy(() => import('./components/pcs/pcs'));
const MaidSecurityJobComponent = lazy(() => import('./components/maid-security-jobs/maid-security-jobs'));


function App() {
  const [checkIframe, setCheckIframe] = useState(false);
  useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    const iframe = params.get("utm_medium");
    if (iframe === "iframe") {
      setCheckIframe(true)
    }
  }, []);

  return (
    <Router>
      <div className="body-main">
        {checkIframe ? null : <HeaderComponent></HeaderComponent>}
        <Suspense fallback={
          <div className="loading d-flex justify-content-center py-4 my-auto">
            <Spinner color='red.500' size='xl' />
          </div>
        }>
          <Routes>
            <Route path="" element={<JobNowComponent />} />
            {/* <Route path="/ifs" element={<IfsComponent />} /> */}
            <Route path="/pcs" element={<PcsComponent />} />
            <Route
              path="/maid-security-jobs"
              element={<MaidSecurityJobComponent />}
            />
            <Route path="*" element={<Navigate to="/" replace />} />
          </Routes>
        </Suspense>

      </div>
    </Router>
  );
}

export default App;
